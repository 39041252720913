import {type Store} from "vuex";
import {type InjectionKey} from "vue";
import Utils from "../utils";
import {download_report, handleSaveResponse} from "./utils";
import {uniqueArray} from "@avvoka/shared";
import { type ISchedule, denormalizeSchedule, normalizeSchedule } from "./scheduler/use-scheduler";
import { Intervals, WeekDays, Months} from "./scheduler/intervals";

const axios = Utils.axios

export interface ICustomReportsStore {
  samples: any,
  selectedSamples: Record<string, number[]>,
  filters: string[],
  displays: IDisplayProperty[],
  system_attributes: string[],
  template_attributes: string[],
  template_attributes_by_templates: Record<string, string[]>,
  template_roles_by_templates: Record<string, string[]>,
  report?: ICustomReport,
  result: boolean,
  resultHtml: string,
  report_users?: ICustomReportUser[],
  organisations: IOrganisation[],
  schedules: ISchedule[],
  profileUsers: Backend.Models.ProfilesUser[],
  profileUsersLoading: boolean,
  samplesLoading: Record<string, boolean>,
  loadingReport: boolean
  downloading: boolean,
  gmtTimeZones: any[],
  template_rules: Record<string, string[]>,
  workflow_reports: boolean
}

interface IOrganisation {
  id: number,
  name: string,
}

export interface ICustomReportUser {
  user_id: number,
  report_id: number,
  rights: string,
}

interface ICustomReport {
  name: string
  id: number
  data: string
  profile_id: number
  organisation_id: number
}

interface IDisplayProperty {
  property?: string
  is_attribute?: boolean
  is_role?: boolean
  is_workflow?: boolean
  workflow_properties?: string[]
}

export type FilterType = 'date' | 'system' | 'states' | 'attributes' | 'workflow'

export const systemAttributeToKey = (att: string): string | undefined => {
  if(!systemAttributesValuesSet.has(att)) return
  const key = Object.keys(systemAttributes).find(key => systemAttributes[key] == att)
  return key
}

export const keyToSystemAttribute = (key: string): string => {
  return systemAttributes[key]
}

export const isDateAttribute = (key: string): boolean => {
  return systemAttributeTypes['date'].includes(key)
}
const TODAY_YYYY_MM_DD = () => {
  let today = new Date()
  const offset = today.getTimezoneOffset()
  today = new Date(today.getTime() - (offset*60*1000))
  return today.toISOString().split('T')[0]
}

export const DEFAULT_DATE_AST = `{'ast':{'Greater':[{'Att':''},{'String':'${TODAY_YYYY_MM_DD()}'}]}}`
const DEFAULT_STATE_AST = `{'ast':{'Equals':[{'Att':'Document State'},{'String':''}]}}`
export const DEFAULT_DATE_WITHIN_AST = `{'ast': {'And':[{'DateCompare':[{'DateOffset': [{'Att': "_avvReportGenerationDate_"}, {'String': "-"}, {'Number': 1}, {'String': "d"}]},{'String':'<='},{'Att':''}]},{'DateCompare':[{'Att':''},{'String':'<='},{'Att':'_avvReportGenerationDate_'}]}]}}` as string
const DEFAULT_WORKFLOW_AST = `{'ast': {"Equals": [{"Att": ""}, {"String": "untriggered"}]}}`

const DEFAULT_AST = (type: FilterType) => {
  switch(type) {
    case 'date':
      return DEFAULT_DATE_AST
    case 'states':
      return DEFAULT_STATE_AST
    case 'workflow':
      return DEFAULT_WORKFLOW_AST
    default:
      return Ast.DEFAULT_AST_STRING
  }
}

export const DEFAULT_OPERATOR = (type: FilterType) => {
  if(type == 'date') return 'Greater'
  return 'Equals'
}

export const normalizeSamples = (samples: any) => {
  samples.templates.active = normalizedTemplates(samples.templates.active)
  samples.templates.archived = normalizedTemplates(samples.templates.archived)

  return samples
}

const normalizedTemplates = (template_versions: IJoinedTemplateVersion[]) => {
  const templates = [] as ITemplate[]
  template_versions.forEach(joinedVersion => {
    const lastTemplate = templates.at(-1)
    if(lastTemplate && lastTemplate.id == joinedVersion.template_id) {
      const tv = getTemplateVersionFromJoined(joinedVersion)
      lastTemplate.nested.push(tv)
      lastTemplate.name = joinedVersion.name
    } else {
      const template = {
        id: joinedVersion.template_id,
        name: joinedVersion.name,
        nested: [getTemplateVersionFromJoined(joinedVersion)],
        latest_published_version_id: joinedVersion.latest_published_version_id
      }
      templates.push(template)
    }
  })
  return templates
}

const getTemplateVersionFromJoined = (jtv: IJoinedTemplateVersion): IVersion => {
  const { id, name, version } = jtv
  return { id, name, version }
}

interface IJoinedTemplateVersion {
  id: number
  latest_published_version_id: number
  template_id: number
  name: string
  version: string
}

interface ITemplate {
  id: number
  latest_published_version_id: number
  name: string
  nested: IVersion[]
}

interface IVersion {
  id: number
  name: string
  version: string
}

export type SamplesType = 'templates' | 'labels' | 'users' | 'profiles'
const DATE_TYPES = ['date', 'DateOffset', 'DateFormat']

export const systemAttributes = {
  'author_name': 'Author Name',
  'template_name': 'Template Name',
  'title': 'Document Name',
  'doc_id': 'Document ID',
  'created_at': 'Document Created',
  'updated_at': 'Document Updated',
  'signed_at' : 'Document Signed',
  'sealed_at': 'Document Sealed',
  'deleted_at': 'Document Deleted',
  'label_name': 'Label Name',
  'first_published_at': 'Document First Published',
  'document_state': 'Document State'
}

export const workflowAttributes = {
  'workflow_name': 'Workflow Name',
  'workflow_state': 'Workflow State',
  'workflow_actions': 'Workflow Action(s)',
  'approver_name': 'Approver Name',
  'workflow_triggered': 'Workflow Triggered',
}

export const workflowValues = ['untriggered', 'completed', 'approved', 'pending', 'rejected']

const systemAttributesValuesSet = new Set(Object.values(systemAttributes))

const systemAttributeTypes = {
  date: ['created_at', 'updated_at', 'signed_at', 'sealed_at', 'deleted_at', 'first_published_at'],
  value: ['author_name', 'title', 'label_name', 'signed_at', 'sealed_at', 'deleted_at', 'first_published_at', 'document_state'],
}

export const documentStates = {
  completed: 'Completed',
  created: 'Created',
  deleted: 'Deleted',
  locked: "Locked",
  partially_signed: 'Partially signed',
  pending_external_signature: 'Pending external signature',
  ready_to_sign: 'Ready to sign',
  signed: 'Signed',
  unlocked: 'Unlocked',
}

const state = (report: ICustomReport) => {
  const data = report ? JSON.parse(report.data) : {}
  return {
    samples: normalizeSamples(avvGon.samples),
    selectedSamples: data.samples ?? {},
    filters: data.filters ?? {},
    displays: data.displays ?? [],
    system_attributes: Object.values(systemAttributes),
    template_attributes: [],
    template_attributes_by_templates: {},
    template_roles_by_templates: {},
    report: report || {},
    report_users: avvGon.report_users,
    result: false,
    organisations: avvGon.organisations,
    schedules: avvGon.schedules.map(normalizeSchedule) ?? [],
    profileUsers: avvGon.profile_users,
    profileUsersLoading: false,
    samplesLoading: {'templates': false, 'profiles': false, 'labels': false, 'authors': false},
    loadingReport: false,
    downloading: false,
    gmtTimeZones: avvGon.gmt_time_zones,
    workflow_reports: avvGon.workflow_reports
  }
}
const actions = {
  async fetch_template_attributes ({state, commit}: {state: ICustomReportsStore, commit: any}) {
    if(!state.selectedSamples['templates']) return
    const template_ids = Object.keys(state.selectedSamples['templates'] ?? {})
    const profile_ids = Object.keys(state.selectedSamples['profiles'] ?? {})
    const response = await axios.post('/dashboard/attributes_by_templates.json', {template_ids, profile_ids})
    commit('SET_TEMPLATE_ATTRIBUTES', response.data)
  },
  async fetch_template_rules({state, commit}: {state: ICustomReportsStore, commit: any}) {
    if(!state.selectedSamples['templates']) return
    const template_ids = Object.keys(state.selectedSamples['templates'] ?? {})
    const profile_ids = Object.keys(state.selectedSamples['profiles'] ?? {})
    const response = await axios.post('/dashboard/template_rules.json', {template_ids, profile_ids})
    commit('SET_TEMPLATE_RULES', response.data)
  },
  async fetch_templates({state, commit}: {state: ICustomReportsStore, commit: any}){
    const profile_ids = Object.keys(state.selectedSamples['profiles'])
    const params = new URLSearchParams
    params.set('profile_ids', profile_ids)
    commit('SET_LOADING_SAMPLES', {key: 'templates', value: true})
    const response = await axios.get(`/custom_reports/templates.json?${params.toString()}`)
    commit('SET_TEMPLATES', response.data.templates)
    commit('SET_LOADING_SAMPLES', {key: 'templates', value: false})
  },
  async fetch_profile_users({state, commit}: {state: ICustomReportsStore, commit: any}){
    state.profileUsersLoading = true
    const profile_id = state.report?.profile_id
    const org_id = state.report?.organisation_id ?? state.organisations[0].id
    const params = new URLSearchParams
    if(profile_id) params.set('profile_id', profile_id)
    params.set('org_id', org_id)
    const response = await axios.get(`/custom_reports/users.json?${params.toString()}`)
    commit('SET_PROFILE_USERS', response.data.users)
    setTimeout(() => {
      state.profileUsersLoading = false
    }, 500)
  },
  async save_report({state, getters, commit}: {state: ICustomReportsStore, getters: any}){
    return new Promise(async (resolve, reject) => {
      const name = state.report?.name
      const postData = JSON.stringify(getters.data)
      const params = {
        data: postData,
        name,
        custom_report_users_attributes: state.report_users,
        custom_report_schedules_attributes: state.schedules.map(schedule => denormalizeSchedule(schedule, state.report?.id)),
        profile_id: state.report?.profile_id,
        organisation_id: state.report?.organisation_id ?? state.organisations[0].id
      }
      let id = state.report?.id
      let success
      if(!id) {
        const url = '/custom_reports.json'
        const { data } = await axios.post(url, params)
        success = handleSaveResponse(data, true)
        if(success) {
          state.report = data.report
          commit('LOAD_NESTED_DATA', data)
        }
      } else {
        const url = `/custom_reports/${id}.json`
        const { data } = await axios.patch(url, params)
        success = handleSaveResponse(data)
        if(success) commit('LOAD_NESTED_DATA', data)
      }
      commit('CLEAR_DELETED_SCHEDULES')
      resolve(success)
    })
  },
  async download({state, commit}: { state: ICustomReportsStore }){
   download_report(state.report!.id, commit)
  }

}
const getters = {
  attribute_types: (state: ICustomReportsStore) => (att: string) => {
    return Object.keys(systemAttributeTypes).filter(key => systemAttributeTypes[key].includes(att))
  },
  attributes: (state: ICustomReportsStore, getters: any) => Object.values(systemAttributes).concat(state.template_attributes).concat(Object.values(state.template_roles_by_templates).flat()),
  date_attributes: (state: ICustomReportsStore) => {
    const systemAtts = systemAttributeTypes['date'].map(keyToSystemAttribute)
    const dateAtts = Object.values(state.template_attributes_by_templates).reduce((acc, val) => {
      const attributes = Object.keys(val)
      const dateAttributes = attributes.filter(att => DATE_TYPES.includes(val[att]))
      return acc.concat(dateAttributes)
    }, [])
    return systemAtts.concat(dateAtts)
  },
  system_attributes: (state: ICustomReportsStore) => systemAttributeTypes['value'].map(keyToSystemAttribute),
  workflow_attributes: (state: ICustomReportsStore) => {
    if(!state.template_rules) return []
    return Object.entries(state.template_rules).reduce((acc, [rule_label, actions]) => {
      const attribtes = actions.map(action => `${rule_label} - ${action}`)
      return acc.concat(attribtes)
    }, [] as string[])
  },
  values_by_type: (state: ICustomReportsStore) => (type: FilterType) => {
    if(!state.filters['Global filters']) return []
    const filters = state.filters['Global filters'][type] ?? []
    const parsedAsts = filters.map((astString: string) => Ast.parse(astString)).filter(e => e)
    const allValues = parsedAsts.reduce((values, ast) => {
      const astValues = Ast.traverse(ast).values
      values.push(...astValues)
      return values
    }, [])
    return uniqueArray(allValues)
  },
  data: (state: ICustomReportsStore) => {
    const {selectedSamples, filters, displays} = state

    return {samples: {...selectedSamples}, filters, displays}
  },
  template_names: (state: ICustomReportsStore) => {
    return Object.keys(state.template_attributes_by_templates)
  }
}
const mutations = {
  SET_SAMPLES(state: ICustomReportsStore, {key, value}: {key: SamplesType, value: number[]}){
    state.selectedSamples[key] = value
  },
  CREATE_FILTER(state: ICustomReportsStore, {_ast, type, templateSelector}:{_ast?: string, type: FilterType, templateSelector: string}){
    const ast = _ast ?? DEFAULT_AST(type)
    if(!state.filters[templateSelector]) state.filters[templateSelector] = {}
    if(!state.filters[templateSelector][type]) state.filters[templateSelector][type] = []
    state.filters[templateSelector][type].push(ast)
  },
  REMOVE_FILTER(state: ICustomReportsStore, {predicate, type, templateSelector}: {predicate: number | string, type: FilterType, templateSelector: string}){
    const predicateIsIndex = typeof predicate == 'number'
    const index = predicateIsIndex ? predicate : state.filters[templateSelector][type].indexOf(predicate)
    state.filters[templateSelector][type].splice(index, 1)
    if(state.filters[templateSelector][type].length === 0) delete state.filters[templateSelector][type]
  },
  UPDATE_FILTER(state: ICustomReportsStore, {ast, index, type, templateSelector}: {ast: string, index: number, type: FilterType, templateSelector: string}){
    state.filters[templateSelector][type][index] = ast
  },
  CREATE_DISPLAY(state: ICustomReportsStore) {
    state.displays.push({property: '', workflow_properties: []})
  },
  UPDATE_DISPLAY(state: ICustomReportsStore, {att, index, bIsSystemAttribute, bIsRole, workflow_property, bIsWorkflowAtt}: {att?: string, index: number, bIsSystemAttribute?: boolean, bIsRole?: boolean, workflow_property?: string, bIsWorkflowAtt?: boolean}){
    const display = state.displays[index]
    const handleFlags = (selectedFlag: string) => {
      const flags = ['is_role', 'is_attribute', 'is_workflow'].filter(e => e !== selectedFlag)
      flags.forEach(flag => display[flag] = false)
    }
    if(att) display.property = att
    if(typeof bIsSystemAttribute == 'boolean') {
      display.is_attribute = !bIsSystemAttribute && !bIsRole
      if(display.is_attribute) handleFlags('is_attribute')
    }
    if(typeof bIsRole == 'boolean') {
      display.is_role = bIsRole
      if(display.is_role) handleFlags('is_role')
    }
    if(typeof bIsWorkflowAtt == 'boolean') {
      display.is_workflow = bIsWorkflowAtt
      if(display.is_workflow) handleFlags('is_workflow')
    }
    if(workflow_property) {
      display.workflow_properties ||= []
      if(!display.workflow_properties.includes(workflow_property)) display.workflow_properties.push(workflow_property)
      else display.workflow_properties = display.workflow_properties.filter(e => e != workflow_property)
    }
  },
  DELETE_DISPLAY(state: ICustomReportsStore, index: number){
    state.displays.splice(index, 1)
  },
  SET_TEMPLATE_ATTRIBUTES(state: ICustomReportsStore, data: any){
    state.template_attributes = data.attributes ?? []
    state.template_attributes_by_templates = data.attributes_by_templates
    state.template_roles_by_templates = data.roles
  },
  SET_TEMPLATE_RULES(state: ICustomReportsStore, data: any){
    state.template_rules = data.rules
  },
  SET_RESULT(state: ICustomReportsStore, result: boolean){
    state.result = result
  },
  SET_TEMPLATES(state: ICustomReportsStore, templates: any) {
    state.samples.templates.active = normalizedTemplates(templates.active)
    state.samples.templates.archived = normalizedTemplates(templates.archived)
  },
  ADD_SCHEDULE(state: ICustomReportsStore, schedule: ISchedule) {
    state.schedules.push(schedule)
  },
  REMOVE_SCHEDULE(state: ICustomReportsStore, index: number) {
    const schedule = state.schedules[index]
    schedule._destroy = "1"
  },
  SET_SCHEDULE_INTERVAL(state: ICustomReportsStore, {index, interval}: {index: number, interval: string}) {
    state.schedules[index].interval = Intervals[interval]
  },
  SET_SCHEDULE_START_DATE(state: ICustomReportsStore, {index, date}: {index: number, date: string}) {
    state.schedules[index].start_date = date
  },
  SET_SCHEDULE_END_DATE(state: ICustomReportsStore, {index, date}: {index: number, date: string}) {
    state.schedules[index].end_date = date
  },
  TOGGLE_SCHEDULE_WEEKDAY(state: ICustomReportsStore, {index, weekday}: {index: number, weekday: string}) {
    const weekDayIndex = WeekDays[weekday]
    if(state.schedules[index].options.week_days.includes(weekDayIndex)){
      state.schedules[index].options.week_days = state.schedules[index].options.week_days.filter(day => day !== weekDayIndex)
    } else {
      state.schedules[index].options.week_days.push(weekDayIndex)
    }
  },
  TOGGLE_SCHEDULE_MONTH(state: ICustomReportsStore, {index, month}: {index: number, month: string}) {
    const monthIndex = Months[month]
    if(state.schedules[index].options.months.includes(monthIndex)){
      state.schedules[index].options.months = state.schedules[index].options.months.filter(day => day !== monthIndex)
    } else {
      state.schedules[index].options.months.push(monthIndex)
    }
  },
  CLEAR_DELETED_SCHEDULES(state: ICustomReportsStore) {
    state.schedules = state.schedules.filter(schedule => !schedule._destroy)
  },
  SET_LOADING_SAMPLES(state: ICustomReportsStore, {key, value}: {key: string, value: boolean}) {
    state.samplesLoading[key] = value
  },
  SET_LOADING(state: ICustomReportsStore, value: boolean) {
    state.loadingReport = value
  },
  LOAD_NESTED_DATA(state: ICustomReportsStore, data: Record<string, any>) {
    state.report_users = data.users
    state.schedules = JSON.parse(data.schedules).map(normalizeSchedule)
  },
  SET_DOWNLOADING(state: ICustomReportsStore, value: boolean) {
    state.downloading = value
  },
  SET_PROFILE_USERS(state: ICustomReportsStore, users: Backend.Models.ProfilesUser[]){
    state.profileUsers = users
  },
  SET_RESULT_HTML(state: ICustomReportsStore, html: string){
    state.resultHtml = html
  }
}

export const createCustomReportsStore = () => {
  const store = Vuex.createStore<ICustomReportsStore>({
    state: state(avvGon.report),
    actions,
    getters,
    mutations,
  });

  store.dispatch('fetch_template_attributes')
  store.dispatch('fetch_template_rules')
  const onTemplatesChange = () => {
    const actions = ['fetch_template_attributes', 'fetch_template_rules']
    actions.forEach(action => store.dispatch(action))
  }
  store.watch((state: ICustomReportsStore) => state.selectedSamples['templates'], onTemplatesChange, { deep: true })
  store.watch((state: ICustomReportsStore) => state.selectedSamples['profiles'], () => store.dispatch('fetch_templates'), { deep: true })
  store.watch((state: ICustomReportsStore) => state.report?.profile_id, () => store.dispatch('fetch_profile_users'), { deep: true })
  store.watch((state: ICustomReportsStore) => state.report?.organisation_id, () => store.dispatch('fetch_profile_users'), { deep: true })

  window.CustomReportStore = store

  return store
}

interface SmartStore<T> extends Omit<Store<T>, "dispatch" | "commit" | "getters"> {
  dispatch<ActionType extends keyof typeof actions>(action: ActionType, payload?: Parameters<typeof actions[ActionType]>[1]): void
  commit<MutationType extends keyof typeof mutations>(mutation: MutationType, payload?: Parameters<typeof mutations[MutationType]>[1]): void
  getters: {[P in keyof typeof getters]: ReturnType<typeof getters[P]>}
}

export const CUSTOM_REPORTS_STORE: InjectionKey<SmartStore<ICustomReportsStore>> = "CustomReportsStore" as any
