<template>
  <div class="avv-group ast-group">
    <div class="flex gap-x-2">
      <AvvSelect placeholder="Select a attribute" :readonly="readonly" :value="compareAttribute.value" :search="true" @select="setAttributes">
        <div class="menu">
          <div class="custom-input z-10">
            <label>
              <input v-model="compareAttribute.valueSearch" type="search" class="avv-input" placeholder="Search...">
            </label>
          </div>
          <template v-for="(name, index) in compareAttribute.values" :key="name">
            <avv-option :value="name" v-text="name"/>
            <div v-if="index === 5" class="separator"></div>
          </template>
        </div>
      </AvvSelect>
      <AvvSelect :value="'Within'" @select="handleOperationSelect">
        <div class="menu">
          <avv-option value="Within" v-text="'Within'"/>
          <avv-option value="From" v-text="'From'"/>
          <avv-option value="To" v-text="'To'"/>
        </div>
      </AvvSelect>
      <input type="number" class="avv-input" :value="dateOffsetValue.relationValue.value" @input="onNumberInput">
      <AvvSelect :placeholder="'Select unit'" :value="dateOffsetUnit.relationValue" @select="setDateOffsetUnit">
        <div class="menu">
          <avv-option v-for="option in unitValues" :key="option.value" :value="option.value" @click.prevent v-text="option.text"/>
        </div>
      </AvvSelect>
      <AvvSelect placeholder="Select a operation" :value="compareOperation.relationValue" @select="setOperations">
        <div class="menu">
          <avv-option v-for="operation in operationValues" :key="operation.value" :value="operation.value" v-text="operation.text"/>
        </div>
      </AvvSelect>
      <label>
        <input type="text" class="avv-input" readonly value="Report Generation Date">
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, inject, toRefs, reactive} from "vue";
import {AstStore} from "../../ast";
import {useAstBuilder, useAttributeSelect} from "../Reusable";
import {
  CUSTOM_REPORTS_STORE,
  DEFAULT_DATE_AST
} from "../../../custom_reports/store";
import AvvSelect from "../../../reusable/components/AvvSelect.vue";
import {DOMEmit} from "../../../dom_utils";

const props = defineProps(["uuid", "parent", "readonly"]);

const {uuid, parent, readonly} = toRefs(props);
const store = inject(AstStore);
const CRStore = inject(CUSTOM_REPORTS_STORE);

const ast = useAstBuilder(store, uuid, parent)
const compareAst = computed(() => useAstBuilder(store, computed(() => ast.relationValue.value[0]), ast.uuid))
const compareAstB = computed(() => useAstBuilder(store, computed(() => ast.relationValue.value[1]), ast.uuid))
const _compareAttribute = computed(() => useAstBuilder(store, computed(() => compareAst.value.relationValue.value[2]), compareAst.value.uuid))
const _compareAttributeB = computed(() => useAstBuilder(store, computed(() => compareAstB.value.relationValue.value[0]), compareAstB.value.uuid))
const compareOperation = computed(() => useAstBuilder(store, computed(() => compareAst.value.relationValue.value[1]), compareAst.value.uuid))
const compareOperationB = computed(() => useAstBuilder(store, computed(() => compareAstB.value.relationValue.value[1]), compareAstB.value.uuid))

const dateOffsetAst = computed(() => useAstBuilder(store, computed(() => compareAst.value.relationValue.value[0]), compareAst.value.uuid))
const dateOffsetOperation = computed(() => useAstBuilder(store, computed(() => dateOffsetAst.value.relationValue.value[1]), dateOffsetAst.value.uuid))
const dateOffsetValue = computed(() => useAstBuilder(store, computed(() => dateOffsetAst.value.relationValue.value[2]), dateOffsetAst.value.uuid))
const dateOffsetUnit = computed(() => useAstBuilder(store, computed(() => dateOffsetAst.value.relationValue.value[3]), dateOffsetAst.value.uuid))

const setAttributes = (option) => {
  const value = option.getAttribute("value");
  compareAttributeB.value = value;
}

const setOperations = option => {
  const value = option.getAttribute("value");
  compareOperation.value.relationValue.value = value;
  compareOperationB.value.relationValue.value = value;
  const dateOffsetOperationValue = value == '<=' ? '-' : "+"
  dateOffsetOperation.value.relationValue.value = dateOffsetOperationValue;
}

const setDateOffsetUnit = option => {
  const value = option.getAttribute("value");
  dateOffsetUnit.value.relationValue.value = value;
}

const parseValue = (value: string) => {
  return {'To': 'Less', 'From': 'Greater'}[value]
}

const handleOperationSelect = option => {
  const value = option.getAttribute("value");
  if(value !== 'Within') {
    const att = compareAttribute.value
    const newAst = DEFAULT_DATE_AST.replaceAll("Greater", parseValue(value))
    let valueWithAtt = newAst
    if(att) {
      const attString = `'Att':'${att}'`
      valueWithAtt = newAst.replaceAll("'Att':''", attString)
    }
    const newAstObject = Ast.parse(valueWithAtt)!.ast
    const astObject = store.getters.astObject
    const paths = store.getters.pathToUuid(uuid.value)
    const replacedAst = Ast.stringify(Ast.replace(astObject, paths, newAstObject))
    DOMEmit('toggle-ast', {astString: replacedAst})
  }
}

const operationValues = [{value: '<=', text: 'Before'}, {value: '>=', text: 'From'}]
const unitValues = [
  {value: 'd',   text: 'Days'},
  {value: 'bd',  text: 'Business Days'},
  {value: 'w',  text: 'Weeks'},
  {value: 'm', text: 'Months'},
  {value: 'y',  text: 'Years'}
]
const compareAttribute = reactive(useAttributeSelect(_compareAttribute.value, CRStore))
const compareAttributeB = reactive(useAttributeSelect(_compareAttributeB.value, CRStore))

const onNumberInput = (e) => {
  const isPositive = e.target.value >= 0;
  if(isPositive) dateOffsetValue.value.relationValue.value = e.target.value;
  else {
    e.target.value = 0
  }
}
</script>
