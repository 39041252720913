import {Optional, uniqueArray, clone} from "@avvoka/shared";
import AvvParser from "../parser";
import {type InjectionKey, toRaw} from "vue";
import DependentList from "./components/astComponents/DependentList.vue";
import Within from "./components/astComponents/Within.vue";
import Equals from "./components/astComponents/Equals.vue";
import NotEquals from "./components/astComponents/NotEquals.vue";
import {type Store} from "vuex";
import {NO_VIS_COND_HUMAN} from "../VisibilityConditions";
import {DateWithinHuman} from "./customHuman";

declare global {
  const localizeText: (...args: any[]) => string;
  interface Window {
    localizeText: (...args: any[]) => string;
  }
}

if(import.meta.env.VITEST) {
  if(window.localizeText === undefined) {
    window.localizeText = (s: string) => s
  }
}

export class TypeBuilder<Len extends number = 0> {
  template: Array<{match: string[], vararg?: true, description?: string, matchType?: string, optional?: true}> = []
  returnType!: "string" | "number" | "object" | "array" | "boolean"

  one(...types: Array<AstFunctionId | "*">) {
    this.template.push({match: types})
    return this;
  }

  many(...types: Array<AstFunctionId | "*">) {
    this.template.push({match: types, vararg: true})
    return this;
  }

  /**
   * Description for the type
   */
  desc(description: string) {
    this.template[this.template.length - 1].description = description;
    return this;
  }

  /**
   * The type should be an array
   */
  get isArray() {
    this.template[this.template.length - 1].matchType = "array";
    return this;
  }

  /** The type is optional */
  get optional() {
    this.template[this.template.length - 1].optional = true;
    return this;
  }

  /** Will return string */
  get string(): TypeBuilder {
    this.returnType = "string"
    return this;
  }

  /** Will return boolean */
  get boolean() {
    this.returnType = "boolean"
    return this;
  }

  /** Will return number */
  get number() {
    this.returnType = "number"
    return this;
  }

  /** Will return array */
  get array() {
    this.returnType = "array"
    return this;
  }

  /** Will return object */
  get object() {
    this.returnType = "object"
    return this;
  }

  getTypesForIndex(index: number): AstFunctionId[] {
    for(let i = 0; i < index + 1; i++) {
      const kind = this.template[i];
      if(kind.vararg || i === index) return uniqueArray(kind.match.map(item => this.matchToType(item)).flat());
    }
    return []
  }

  matchToType(match: string): AstFunctionId[] {
    if(match === "*") return Object.keys(AST_FUNCTIONS) as AstFunctionId[];
    return [match] as AstFunctionId[];
  }

  canAddNextNode(len: number){
    return this.template.find(item => item.vararg) || len < this.template.length
  }
}

export const AstStore: InjectionKey<Store<AstStoreType>> = Symbol()
export const AST_TYPES = ["Iterator", "Map", "Row", "String", "Att", "AttArray", "Boolean", "Number", "List", "Datasheet", "DependentList", "IteratedAtt", "Equals", "NotEquals", "GreaterEqual", "Greater", "Less", "LessEqual", "Add", "Subtract", "Multiply", "Divide", "Sum", "Round", "Concatenate", "Upper", "Lower", "Capitalise", "Gsub", "Contains", "NotContains", "Not", "If", "And", "Or", "Present", "NotPresent", "Includes", "LongDate", "DateFormat", "DateOffset", "DateDifference", "InWords", "FormatNumber", "FormatNumberPrecision", "Join", "Count", "AttDefault", "NonRepeated", "Member", "Collect", "MemberNth", "MemberIndex", "NotKnown", "At", "IndexOf", "Listify", "Select", "StartWith", "EndWith", "Dictionary", "Reference", "DateCompare", "Split", "StrFirst", "StrLast", "StrLength", "SentenceCase", "DateWithin", "Sysdate", "RemoveBlankValues", "RemoveDuplicates"] as const
export type AstFunctionId = typeof AST_TYPES[number]
export type AstFunctionsType = {[key in AstFunctionId]: {group: string, name: string, type: TypeBuilder | StringConstructor | NumberConstructor}}
export const AST_FUNCTIONS: AstFunctionsType = {
  String:        {group: "Inputs"       , name: localizeText('general.ast_functions.string')                , type: String},
  Att:           {group: "Inputs"       , name: localizeText('general.ast_functions.att')                   , type: String},
  AttArray:      {group: "Inputs"       , name: localizeText('general.ast_functions.att_array')             , type: String},
  Number:        {group: "Inputs"       , name: localizeText('general.ast_functions.number')                , type: Number},
  List:          {group: "Inputs"       , name: localizeText('general.ast_functions.list')                  , type: new TypeBuilder().many("*")},
  Datasheet:     {group: "Inputs"       , name: localizeText('general.ast_functions.datasheet')             , type: new TypeBuilder().many("*")},
  DependentList: {group: "Inputs"       , name: localizeText('general.ast_functions.dependent_list')        , type: new TypeBuilder().many("*")},
  IteratedAtt:   {group: "Inputs"       , name: localizeText('general.ast_functions.iterated_att')          , type: new TypeBuilder<2>().one("Att").one("*")},
  Dictionary:    {group: "Inputs"       , name: localizeText('general.ast_functions.dictionary')            , type: new TypeBuilder().many("*").object},
  Boolean:       {group: "Inputs"       , name: localizeText('general.ast_functions.boolean')               , type: String},
  Equals:        {group: "Comparison"   , name: localizeText('general.ast_functions.equals')                , type: new TypeBuilder<2>().one("*").one("*").boolean},
  NotEquals:     {group: "Comparison"   , name: localizeText('general.ast_functions.not_equals')            , type: new TypeBuilder<2>().one("*").one("*").boolean},
  GreaterEqual:  {group: "Comparison"   , name: localizeText('general.ast_functions.greater_equal')         , type: new TypeBuilder<2>().one("*").one("*").boolean},
  Greater:       {group: "Comparison"   , name: localizeText('general.ast_functions.greater')               , type: new TypeBuilder<2>().one("*").one("*").boolean},
  Less:          {group: "Comparison"   , name: localizeText('general.ast_functions.less')                  , type: new TypeBuilder<2>().one("*").one("*").boolean},
  LessEqual:     {group: "Comparison"   , name: localizeText('general.ast_functions.less_equal')            , type: new TypeBuilder<2>().one("*").one("*").boolean},
  Add:           {group: "Math"         , name: localizeText('general.ast_functions.add')                   , type: new TypeBuilder<2>().one("*").one("*").number},
  Subtract:      {group: "Math"         , name: localizeText('general.ast_functions.subtract')              , type: new TypeBuilder<2>().one("*").one("*").number},
  Multiply:      {group: "Math"         , name: localizeText('general.ast_functions.multiply')              , type: new TypeBuilder<2>().one("*").one("*").number},
  Divide:        {group: "Math"         , name: localizeText('general.ast_functions.divide')                , type: new TypeBuilder<2>().one("*").one("*").number},
  Sum:           {group: "Math"         , name: localizeText('general.ast_functions.sum')                   , type: new TypeBuilder().many("*").number},
  Round:         {group: "Math"         , name: localizeText('general.ast_functions.round')                 , type: new TypeBuilder<2>().one("*").one("Number").number},
  Concatenate:   {group: "Text"         , name: localizeText('general.ast_functions.concatenate')           , type: new TypeBuilder().many("*").string},
  Upper:         {group: "Text"         , name: localizeText('general.ast_functions.upper')                 , type: new TypeBuilder<1>().one("*").string},
  Lower:         {group: "Text"         , name: localizeText('general.ast_functions.lower')                 , type: new TypeBuilder<1>().one("*").string},
  Capitalise:    {group: "Text"         , name: localizeText('general.ast_functions.capitalise')            , type: new TypeBuilder<1>().one("*").string},
  SentenceCase:  {group: "Text"         , name: localizeText('general.ast_functions.sentence_case')         , type: new TypeBuilder<1>().one("*").string},
  Gsub:          {group: "Text"         , name: localizeText('general.ast_functions.gsub')                  , type: new TypeBuilder<3>().one("*").one("String").one("String").string},
  Contains:      {group: "Text"         , name: localizeText('general.ast_functions.contains')              , type: new TypeBuilder<2>().one("*").one("*").boolean},
  NotContains:   {group: "Text"         , name: localizeText('general.ast_functions.not_contains')          , type: new TypeBuilder<2>().one("*").one("*").boolean},
  StartWith:     {group: "Text"         , name: localizeText('general.ast_functions.start_with')            , type: new TypeBuilder<2>().one("*").one("*").boolean},
  EndWith:       {group: "Text"         , name: localizeText('general.ast_functions.end_with')              , type: new TypeBuilder<2>().one("*").one("*").boolean},
  StrLength:     {group: "Text"         , name: localizeText('general.ast_functions.str_length')            , type: new TypeBuilder<1>().one("*").number},
  StrFirst:      {group: "Text"         , name: localizeText('general.ast_functions.str_first')             , type: new TypeBuilder<1 | 2>().many("*").string},
  StrLast:       {group: "Text"         , name: localizeText('general.ast_functions.str_last')              , type: new TypeBuilder<1 | 2>().many("*").string},
  Split:         {group: "Text"         , name: localizeText('general.ast_functions.split')                 , type: new TypeBuilder<1 | 2>().many("*").object},
  Not:           {group: "Logic"        , name: localizeText('general.ast_functions.not')                   , type: new TypeBuilder<1>().one("*").boolean},
  If:            {group: "Logic"        , name: localizeText('general.ast_functions.if')                    , type: new TypeBuilder<3>().one("*").one("*").one("*").object},
  And:           {group: "Logic"        , name: localizeText('general.ast_functions.and')                   , type: new TypeBuilder().many("*").boolean},
  Or:            {group: "Logic"        , name: localizeText('general.ast_functions.or')                    , type: new TypeBuilder().many("*").boolean},
  Present:       {group: "Logic"        , name: localizeText('general.ast_functions.present')               , type: new TypeBuilder<1>().one("*").boolean},
  NotPresent:    {group: "Logic"        , name: localizeText('general.ast_functions.not_present')           , type: new TypeBuilder<1>().one("*").boolean},
  Includes:      {group: "Logic"        , name: localizeText('general.ast_functions.includes')              , type: new TypeBuilder<2>().one("*").one("*").boolean},
  /** @deprecated LongDate is superseded by DateFormat */
  LongDate:      {group: "Localisation" , name: localizeText('general.ast_functions.long_date')             , type: new TypeBuilder<2>().one("*").one("String").string},
  DateFormat:    {group: "Localisation" , name: localizeText('general.ast_functions.date_format')           , type: new TypeBuilder<3>().one("*").one("String").one("String").string},
  DateOffset:    {group: "Localisation" , name: localizeText('general.ast_functions.date_offset')           , type: new TypeBuilder<4>().one("*").one("String").one("Number").one("String").string},
  DateDifference:{group: "Localisation" , name: localizeText('general.ast_functions.date_difference')       , type: new TypeBuilder<3>().one("*").one("*").one("String").string},
  DateCompare:   {group: "Localisation" , name: localizeText('general.ast_functions.date_compare')          , type: new TypeBuilder<3>().one("*").one("String").one("*").boolean},
  InWords:       {group: "Localisation" , name: localizeText('general.ast_functions.in_words')              , type: new TypeBuilder<3>().one("*").one("String").one("String").string},
  FormatNumber:  {group: "Localisation" , name: localizeText('general.ast_functions.format_number')         , type: new TypeBuilder<2>().one("*").one("String").string},
  FormatNumberPrecision:
                 {group: "Localisation" , name: localizeText('general.ast_functions.format_number_precision'),type: new TypeBuilder<2>().one("*").one("String").one("String").one("String").string},
  Sysdate:       {group: "Localisation" , name: localizeText('general.ast_functions.sysdate')               , type: String},
  Join:          {group: "Array"        , name: localizeText('general.ast_functions.join')                  , type: new TypeBuilder<2 | 3 | 4>()
    .one("*")
    .one("String").desc("Separator")
    .one("String").desc("Last separator").optional
    .one("String").desc("Two words separator").optional
    .string},
  Count:             {group: "Array"        , name: localizeText('general.ast_functions.count')                 , type: new TypeBuilder<1 | 2>().one("*").one("String").number},
  AttDefault:        {group: ""             , name: localizeText('general.ast_functions.att_default')           , type: new TypeBuilder<2>().one("Att").one("String")},
  NonRepeated:       {group: ""             , name: localizeText('general.ast_functions.non_repeated')          , type: new TypeBuilder().many("*").object},
  Member:            {group: ""             , name: localizeText('general.ast_functions.member')                , type: new TypeBuilder<2>().one("*").one("*").object},
  Collect:           {group: ""             , name: localizeText('general.ast_functions.collect')               , type: new TypeBuilder().many("*").object},
  MemberNth:         {group: ""             , name: localizeText('general.ast_functions.member_nth')            , type: new TypeBuilder().many("*").object},
  MemberIndex:       {group: ""             , name: localizeText('general.ast_functions.member_index')          , type: new TypeBuilder().many("*").object},
  NotKnown:          {group: ""             , name: localizeText('general.ast_functions.not_known')             , type: new TypeBuilder().many("*").object},
  At:                {group: "Array"        , name: localizeText('general.ast_functions.at')                    , type: new TypeBuilder<2>().one("Att").isArray.one("*").object},
  IndexOf:           {group: ""             , name: localizeText('general.ast_functions.index_of')              , type: new TypeBuilder<2>().one("Att").isArray.one("String", "Number").number},
  Listify:           {group: ""             , name: localizeText('general.ast_functions.listify')               , type: new TypeBuilder().many("*").array},
  Select:            {group: ""             , name: localizeText('general.ast_functions.select')                , type: new TypeBuilder<2>().one("Att").isArray.one("String").array},
  Iterator:          {group: "Array"        , name: localizeText('general.ast_functions.iterator')              , type: String},
  Map:               {group: "Array"        , name: localizeText('general.ast_functions.map')                   , type: new TypeBuilder<4>().one("String").one("*").one("*").one("*").object},
  Row:               {group: "Array"        , name: localizeText('general.ast_functions.row')                   , type: new TypeBuilder().one("String").many("*").object},
  Reference:         {group: "Array"        , name: localizeText('general.ast_functions.reference')             , type: new TypeBuilder<2 | 3>().one("*").one("*").one("*").optional.object},
  RemoveBlankValues: {group: "Array"        , name: localizeText('general.ast_functions.remove_blank_values')   , type: new TypeBuilder<1>().one("*").object},
  RemoveDuplicates:  {group: "Array"        , name: localizeText('general.ast_functions.remove_duplicates')     , type: new TypeBuilder<1>().one("*").object},
} as const

export type AstFunctionType<Type> = Type extends StringConstructor | NumberConstructor ? ReturnType<Type> :
  Type extends Array<infer X> ? (X extends "all" ? Array<AstType> : X) : Type;

export type AstFunctionDefinition<Id extends AstFunctionId> = {
  group: string,
  name: string,
  type: AstFunctionType<typeof AST_FUNCTIONS[Id]["type"]>
}

export type ValueTypes = {Att: string, String: string, AttArray: string, AttDefault: string, Number: number, Iterator: string, Boolean: boolean}
export type AstType<K extends AstFunctionId = AstFunctionId> =
  Partial<ValueTypes> &
  {[Identifier in Exclude<K, keyof ValueTypes>]?: Array<AstType>}

export type SuperAstType = (AstType | {ast: AstType}) & {uuid?: string, showUnanswered?: boolean}
export type AstBox = {ast: AstType, uuid?: string, showUnanswered?: boolean}

// activated in QToolbar.vue:71
export const AST_TEMPLATES: Partial<Record<AstFunctionId, {templates: Array<AstType>, info: string, widget: any, custom?: (ast: AstType) => string, matchByDefault?: boolean}>> = {

  Datasheet: {widget: null, templates: [{Join: [{Datasheet: [{String: ""}, {Att: ""}, {String: ""}, {String: ""}]}]}, {Datasheet: [{String: ""}, {Att: ""}, {String: ""}, {String: ""}]}], info: `Lookup values a dependent list from a key. Takes 3 values: <ol><li><field-key>Name of Dependent list</field-key> the name of the dependent list to search</li><li><field-key>Lookup value</field-key> the key to search for in the dependent list</li><li><field-key>Joining value</field-key> a separator that should be used between each value if multiple values are returned</li></ol>`},
  DependentList: {widget: DependentList, templates: [{Join: [{DependentList: [{String: ""}, {Att: ""}]}, {String: ""}]}, {DependentList: [{String: ""}, {Att: ""}]}], info: `Lookup values a dependent list from a key. Takes 3 values: <ol><li><field-key>Name of Dependent list</field-key> the name of the dependent list to search</li><li><field-key>Lookup value</field-key> the key to search for in the dependent list</li><li><field-key>Joining value</field-key> a separator that should be used between each value if multiple values are returned</li></ol>`},
  DateFormat: {widget: null, templates: [{DateFormat: [{Att: ""}, {String: "default"}, {String: "en_GB"}]}], info: `Convert a date value into a long date format (e.g. 24 January 2020). Takes 3 values:<ol><li><field-key>Date Attribute</field-key> the date to be converted</li><li><field-key>Date Format Structure</field-key> the format the long date should take (see this link for guidance)</li><li><field-key>Locale</field-key> the locale the formatted date should take (see this link for guidance)</li></ol>`},
  DateOffset: {widget: null, templates: [{DateOffset: [{Att: ""}, {String: "+"}, {Number: 14}, {String: "d"}]}], info: `Adds or subtract time period from date. Takes 4 values:<ol><li><field-key>Date Attribute</field-key>  the date for offset</li><li><field-key>Operation </field-key> + or -</li><li><field-key>Offset </field-key> number of given units to offset the date</li><li><field-key>Time period unit</field-key> units of operation. Years, months, weeks or days in form of shortcut y, m, w or d</li></ol>`},
  DateDifference: {widget: null, templates: [{DateDifference: [{Att: ""}, {Att: ""}, {String: "d"}]}], info: `Calculates distance/difference between two dates. Takes 3 values:<ol><li><field-key>Date From Attribute</field-key>  the first date</li><li><field-key>Date To Attribute </field-key> the second date</li><li><field-key>Time diffrence unit</field-key> units of operation. Years, months, weeks or days in form of shortcut y, m, w or d</li></ol>`},
  DateCompare: {widget: null, templates: [{DateCompare: [{Att: ""}, {String: "="}, {Att: ""}]}], info: `Compares two dates using selected operation. Takes 3 values:<ol><li><field-key>Date 1</field-key></li><li><field-key>Comparison operator</field-key> we want to use</li><li><field-key>Date 2</field-key></li></ol>`},
  LongDate: {widget: null, templates: [{LongDate: [{Att: ""}, {String: "en_GB"}]}], info: `Convert a date value into a long date format (e.g. 24 January 2020). Takes 3 values:<ol><li><field-key>Date Attribute</field-key> the date to be converted</li><li><field-key>Date Format Structure</field-key> the format the long date should take (see this link for guidance)</li><li><field-key>Locale</field-key> the locale the formatted date should take (see this link for guidance)</li></ol>`},
  InWords: {widget: null, templates: [{InWords: [{Att: ""}, {String: "en_GB"}]}], info: `Display a numerical value in words. Takes 2 values:<ol><li><field-key>Number</field-key> the number to be converted, this can be a static number or an attribute</li><li><field-key>Locale</field-key> the locale that should be used to convert the number to words (see this link for guidance)</li></ol>`},
  FormatNumber: {widget: null, templates: [{FormatNumber: [{Att: ""}, {String: "."}]}], info: `Format a number in conformity with a locale. Takes 2 values:<ol><li><field-key>Number</field-key> the number to be converted, this can be a static number or an attribute</li><li><field-key>Locale</field-key> the locale that should be used to display the number (see this link for guidance)</li></ol>`},
  Equals: {widget: Equals, templates: [{Equals: [{Att: ""}, {String: ""}]}, {Equals: [{Att: ""}, {Number: 0}]}], info: `Checks whether two values are equal to one another and returns true if they are. Takes 2 values:<ol><li><field-key>Attribute</field-key> the first value to be compared</li><li><field-key>Value</field-key> the second value to be compared</li></ol>`},
  NotEquals: {widget: NotEquals, templates: [{NotEquals: [{Att: ""}, {String: ""}]}, {NotEquals: [{Att: ""}, {Number: 0}]}], info: `Checks whether two values are not equal to one another and returns true if they aren't equal. Takes 2 values:<ol><li><field-key>Attribute</field-key> the first value to be compared</li><li><field-key>Value</field-key> the second value to be compared</li></ol>`},
  Greater: {widget: null, templates: [{Greater: [{Att: ""}, {Number: 0}]}], info: `Checks whether the first value is greater than the second and returns true if it is greater. Takes 2 values:<ol><li><field-key>Attribute</field-key> the first value to be compared</li><li><field-key>Value</field-key> the second value to be compared</li></ol>`},
  GreaterEqual: {widget: null, templates: [{GreaterEqual: [{Att: ""}, {Number: 0}]}], info: `Checks whether the first value is greater than or equal to the second and returns true if it is greater or equal. Takes 2 values:<ol><li><field-key>Attribute</field-key> the first value to be compared</li><li><field-key>Value</field-key> the second value to be compared</li></ol>`},
  Less: {widget: null, templates: [{Less: [{Att: ""}, {Number: 0}]}], info: `Checks whether the first value is less than the second and returns true if it is lesser. Takes 2 values:<ol><li><field-key>Attribute</field-key> the first value to be compared</li><li><field-key>Value</field-key> the second value to be compared</li></ol>`},
  LessEqual: {widget: null, templates: [{LessEqual: [{Att: ""}, {Number: 0}]}], info: `Checks whether the first value is less than or equal to the second and returns true if it is lesser or equal. Takes 2 values:<ol><li><field-key>Attribute</field-key> the first value to be compared</li><li><field-key>Value</field-key> the second value to be compared</li></ol>`},
  Sum: {widget: null, templates: [{Sum: []}], info: `Adds the provided values together than returns the result. This can take any number of number values:<ol><li><field-key>Value 1</field-key> the first value to be added (this can be an attribute or a static number)</li><li><field-key>Following Values</field-key> the following value(s) to be added (these can be an attribute or a static number)</li></ol>`},
  Subtract: {widget: null, templates: [{Subtract: []}], info: `Takes the first value and subtracts the following values, returning the result. This can take any number of number values:<ol><li><field-key>Value 1</field-key> the first value to be added (this can be an attribute or a static number)</li><li><field-key>Following Values</field-key> the following value(s) to be added (these can be an attribute or a static number)</li></ol>`},
  Multiply: {widget: null, templates: [{Multiply: [{Att: ""}, {Number: 0}]}], info: `Multiplies the first value by the second, returning the result. This takes two values:<ol><li><field-key>Value 1</field-key> the value to be multiplied by the second (this can be an attribute or a static number)</li><li><field-key>Following Values</field-key> the value to multiply the first vale by (this can be an attribute or a static number)</li></ol>`},
  Divide: {widget: null, templates: [{Divide: [{Att: ""}, {Number: 0}]}], info: `Divides the first value by the second, returning the result. This takes two values:<ol><li><field-key>Value 1</field-key> the first value to be divided by the second (this can be an attribute or a static number)</li><li><field-key>Value 2</field-key> the value to divide the first value by (this can be an attribute or a static number)</li></ol>`},
  Round: {widget: null, templates: [{Round: [{Att: ""}, {Number: 0}]}], info: `Rounds the first value to a given number of decimal places. This takes two values:<ol><li><field-key>Number</field-key> the value to be rounded (this can be an attribute or a static number)</li><li><field-key>Number of decimal places</field-key> the number of decimal places the first value should be rounded to</li></ol>`},
  Concatenate: {widget: null, templates: [{Concatenate: []}], info: `Joins together the inputs provided, from top to bottom and returns the result. This can take any number of values:<ol><li><field-key>Value</field-key> the first value (this can be an attribute or a static value)</li><li><field-key>Following values</field-key> the subsequent values to be joined to the end of the first value (these can be attributes or static values)</li></ol>`},
  Contains: {widget: null, templates: [{Contains: [{Att: ""}, {String: ""}]}], info: `Checks whether the second value is contained within the first and returns true if it is. This takes 2 values:<ol><li><field-key>String to search in</field-key> the string to search for the second value in (this can be an attribute or a static value)</li><li><field-key>String to search for</field-key> the string to search for in the first value (this can be an attribute or a static value)</li></ol>`},
  NotContains: {widget: null, templates: [{NotContains: [{Att: ""}, {String: ""}]}], info: `Checks whether the second value is not contained within the first and returns true if it is not. This takes 2 values:<ol><li><field-key>String to search in</field-key> the string to search for the second value in (this can be an attribute or a static value)</li><li><field-key>String to search for</field-key> the string to search for in the first value (this can be an attribute or a static value)</li></ol>`},
  Upper: {widget: null, templates: [{Upper: []}], info: `Converts the string to be entirely uppercase. This takes 1 value:<ol><li><field-key>String to convert</field-key> the string to convert to uppercase</li></ol>`},
  Lower: {widget: null, templates: [{Lower: []}], info: `Converts the string to be entirely lowercase. This takes 1 value:<ol><li><field-key>String to convert</field-key> the string to convert to lowercase</li></ol>`},
  Capitalise: {widget: null, templates: [{Capitalise: []}], info: `Converts the string to be capitalised (the first letter of each word only in upper case). This takes 1 value:<ol><li><field-key>String to convert</field-key> the string to capitalise</li></ol>`},
  SentenceCase: {widget: null, templates: [{SentenceCase: []}], info: `Converts the string to be converted to sentence case (the first letter in upper case). This takes 1 value:<ol><li><field-key>String to convert</field-key> the string to convert to sentence case</li></ol>`},
  If: {widget: null, templates: [{If: [{Equals: []}, {String: ""}, {String: ""}]}], info: `Checks whether a logical test is satisfied and returns one value if it is true or another if false.<ol><li><field-key>Logical test</field-key> the test to be satisfied</li><li><field-key>Value 1</field-key> the value to be returned if the test is satisfied</li><li><field-key>Value 2</field-key> the value to be returned if the test is not satisfied</li></ol>`},
  Not: {widget: null, templates: [{Not: []}], info: `Returns the inverse of the block contained inside (i.e. if the block contained is true, false will be returned).<ol><li><field-key>Logical test</field-key> the test, the result of which should be inverted</li></ol>`},
  And: {widget: null, templates: [{And: []}], info: `Checks whether all logical tests in the block are satisfied and returns true if all of them are true.<ol><li><field-key>Logical test(s)</field-key> the test(s) to be satisfied</li></ol>`},
  Or: {widget: null, templates: [{Or: []}], info: `Checks whether any logical tests are satisfied and returns true if any of them are true.<ol><li><field-key>Logical test(s)</field-key> the test(s) to be satisfied</li></ol>`},
  Present: {widget: null, templates: [{Present: []}], info: `Checks whether an attribute has a value, returning true if it does.<ol><li><field-key>Attribute</field-key> the attribute to have its value checked</li></ol>`},
  NotPresent: {widget: null, templates: [{NotPresent: []}], info: `Checks whether an attribute does not have a value, returning true if it does not.<ol><li><field-key>Attribute</field-key> the attribute to have its value checked</li></ol>`},
  StartWith: {widget: null, templates: [{StartWith: []}], info: `Checks whether the string starts with the specified text`},
  EndWith: {widget: null, templates: [{EndWith: []}], info: `Checks whether the string ends with the specified text`},
  Dictionary: {widget: null, templates: [{Dictionary: []}], info: `Creates a dictionary with key and value pairs from specified items (Must contain even number of items)`},
  Reference: {widget: null, templates: [{Reference: []}], info: `Returns a value that is associated with specified key. This takes 2 or 3 values:<ol><li><field-key>Dictionary</field-key> to be searched</li><li><field-key>Key</field-key> that we want to find value for</li><li><field-key>Default value</field-key> (optional value in case we cannot find our key)</li></ol>`},
  DateWithin: {widget: Within, templates: [{And:[{DateCompare:[{DateOffset: [{Att: ""}, {String: "+"}, {Number: 14}, {String: "d"}]},{String:''},{Att:''}]},{DateCompare:[{Att:''},{'String':''},{'Att':''}]}]}], info: '', custom: DateWithinHuman, matchByDefault: false},
  Sysdate: {width: null, templates: [{Sysdate: "created_at"}], info: `Returns a specific date from the document`}
}

export const AstGroupMath = Object.entries(AST_FUNCTIONS).reduce<Record<string, typeof AST_FUNCTIONS[keyof typeof AST_FUNCTIONS]>>((obj, [key, value]) => {
  if(value.group === "Math") obj[key] = value;
  return obj;
}, {})

export const AstGroupComparison = Object.entries(AST_FUNCTIONS).reduce<Record<string, typeof AST_FUNCTIONS[keyof typeof AST_FUNCTIONS]>>((obj, [key, value]) => {
  if(value.group === "Comparison") obj[key] = value;
  return obj;
}, {})

export interface AstStoreType {
  byUUID: { [key: string]: AstType },
  main: string,
  ast_menu: any
  operations: string[]
  attributes: string[]
  values: string[]
}

// Maps identifiers to new keys
export const AstIdentMapping = {
  'Compact': 'RemoveBlankValues'
} as const

export default class Ast {
  static readonly EMPTY_AST_STRING = `{'ast': {}}`
  static readonly DEFAULT_AST_STRING = `{'ast': {"Equals": [{"Att": ""}, {"String": "Yes"}]}}`
  static Renderer: any;
  static Builders: { ast: any; condition: any; };

  /** Converts id to normalized id
   * for example:  dependentlist to DependentList
   * or throws error when id doesn't exist */
  static normalizeId(id: string): AstFunctionId | never {
    /** lookup if id is fine */
    if (AST_FUNCTIONS[id as keyof typeof AST_FUNCTIONS] !== undefined) return id as AstFunctionId;

    let ident: string | undefined = id.toLowerCase()
    ident = Object.keys(AST_FUNCTIONS).concat(Object.keys(AstIdentMapping)).find(key => key.toLowerCase() === ident)
    if (ident != null) return ident as AstFunctionId
    throw new Error(`Cannot normalize id: ${String(id)}. [id doesn't exist]`)
  }

  /** Iterates over ast tree and gives each node to `acceptor` */
  static forEach(definition: AstType, acceptor: (definition: AstType, parent: AstType) => boolean | void, parent = definition) {
    if (Array.isArray(definition)) {
      for (let i = 0; i < definition.length; i++) {
        if (Ast.forEach(definition[i], acceptor, parent)) { return; }
      }
    } else if (definition instanceof Object) {
      const result = acceptor(definition, parent);
      const keys = Object.keys(definition)
      for (let i = 0; i < keys.length; i++) {
        const def = definition[keys[i]];
        if (def instanceof Array || def instanceof Object) {
          if (Ast.forEach(def, acceptor, definition)) { return; }
        }
      }
      return result;
    }
  }

  static upgrade(definition: AstBox): AstBox {
    Ast.forEach(definition.ast, (node: AstType) => {
      const nodeId = Ast.getNodeId(node, true)
      if(nodeId && nodeId in AstIdentMapping) {
        const newNodeId = AstIdentMapping[nodeId as keyof typeof AstIdentMapping]
        node[newNodeId] = node[nodeId] as never
        delete node[nodeId]
      }
    })
    return definition
  }

  static parseMaybe(str?: string): Optional<AstBox> {
    if(!str) return Optional.empty()
    try {
      let obj = JSON.parse(str.replace(/'/g, '"'));
      if (!("ast" in obj)) obj = this.upgrade({ast: parsed})
      return Optional.of(this.upgrade(obj) as AstBox)
    } catch (e) {}
    return Optional.empty()
  }

  static parse(astString?: string, wrapInAst?: false): AstBox['ast'] | null;
  static parse(astString?: string, wrapInAst?: true): AstBox | null;
  static parse(astString?: string, wrapInAst = true): AstBox | AstBox['ast'] | null {
    if(!astString) return null
    try {
      //TODO Replace only not escaped single quotes
      const parsed = JSON.parse(astString.replace(/'/g, '"')) as SuperAstType;
      if(wrapInAst && !('ast' in parsed)) return this.upgrade({ast: parsed})
      return this.upgrade(parsed as AstBox);
    } catch (e) {
      return null;
    }
  }

  static stringify(definition: SuperAstType): string {
    //TODO Replace only not escaped double quotes
    return JSON.stringify(Ast.normalize(definition)).replace(/"/g, "'")
  }

  static replace(obj: Record<string, unknown>, paths: Array<string | number>, newValue: Record<string, unknown>){
    function setDeepValue(obj: Record<string, unknown>, [prop, ...path]: Array<string | number>, value: Record<string, unknown>) {
      if (!path.length) {
          obj[prop] = value; 
      } else {
          if (!(prop in obj)) obj[prop] = {};
          setDeepValue(obj[prop] as Record<string, unknown>, path, value);
      }
    }

    let _obj = clone(obj)
    setDeepValue(_obj, paths, newValue)
    return _obj
  } 

  static matchWidget(state: Omit<AstStoreType, "attributes" | "operations" | "values"> | AstType) {
    if(!state) return null;

    let ast: AstType
    if(typeof state === "object" && "byUUID" in state) {
      ast = this.fromState(state).ast;
    } else {
      ast = state;
    }

    for(const [_, entry] of Object.entries(AST_TEMPLATES)) {
      if(entry.templates.some(temp => this.compare(ast, temp, true))) {
        if(entry.widget == null) continue;
        return entry
      }
    }

    return null;
  }

  /** Returns all attributes + values from ast node */
  static traverse(definition: SuperAstType, unique = true): {attributes: string[], values: string[]} {
    const ast = Ast.denormalize(definition);
    const attributes: string[] = [];
    const values: string[] = [];
    Ast.forEach("ast" in ast ? ast.ast : ast, (node: AstType) => {
      if (node["String"] !== undefined) { values.push(String(node["String"])); }
      if (node["Number"] !== undefined) { values.push(String(node["Number"])); }
      if (node["Iterator"] !== undefined) { values.push(String(node["AttArray"])); }
      if (node["Att"] !== undefined) { attributes.push(String(node["Att"])); }
      if (node["AttArray"] !== undefined) { attributes.push(String(node["AttArray"])); }
    });
    return {
      attributes: unique ? Ast.uniqueArray(attributes) : attributes,
      values: unique ? Ast.uniqueArray(values) : values,
    };
  }

  static getOperator(definition: SuperAstType): string[] {
    let astWrapper = Ast.denormalize(definition);
    if(astWrapper['ast'] && astWrapper['ast']['And']) astWrapper = astWrapper['ast']['And'];
    if(Array.isArray(astWrapper)){
      const keys = astWrapper.map(obj => {
        return this.getOperator(obj)[0]
      })
      return keys 
    }
    const keys = Object.keys(astWrapper['ast'] || astWrapper)
    return [keys.shift()] as string[]
  }

  static markArray<T>(arg1: T | T[]): arg1 is T[] {
    return true;
  }

  static isEmpty(definition: SuperAstType | AstType) {
    if("ast" in definition) definition = definition.ast;
    return this.getNodeId(definition, false) == null
  }

  /** Compares two ast trees */
  static compare(definition1: SuperAstType, definition2: SuperAstType, compareKeysOnly = false): boolean {
    if(definition1.showUnanswered !== definition2.showUnanswered) return false
    if("ast" in definition1) definition1 = definition1.ast
    if("ast" in definition2) definition2 = definition2.ast

    const compare = (ast1: AstType, ast2: AstType): boolean => {
      const isArr1 = this.isArray(ast1), isArr2 = this.isArray(ast2);
      if(isArr1 !== isArr2) return false;
      if(isArr1 && this.markArray(ast1) && this.markArray(ast2)) {
        if(ast1.length !== ast2.length) return false;
        const len = ast1.length
        const matchStack: number[] = []
        for(let i = 0; i < len; i++) {
          const item1 = ast1[i];
          let _match = false
          for(let j = 0; j < len; j++) {
            const item2 = ast2[j];
            if(!matchStack.includes(j) && compare(item1, item2)) {
              _match = true;
              matchStack.push(j)
              break;
            }
          }
          if(!_match) return false;
        }
        return true;
      } else {
        const isObj1 = this.isObject(ast1), isObj2 = this.isObject(ast2);
        if(isObj1 !== isObj2) return false;
        if(isObj1) {
          const id1 = this.getNodeId(ast1), id2 = this.getNodeId(ast2)
          if(id1 !== id2) return false;
          return compare(ast1[id1], ast2[id2]);
        } else {
          // string | number
          if(compareKeysOnly) return true;

          const type1 = typeof ast1, type2 = typeof ast2
          if(type1 !== type2) return false;
          return ast1 === ast2
        }
      }
    }

    try {
      return compare(definition1, definition2);
    } catch (ignore) {}
    return false;
  }

  static getNodeId(definition: AstType | string | number, normalize = true): AstFunctionId | undefined {
    if (!Ast.isObject(definition)) { return undefined; }
    const keys = Object.keys(AST_FUNCTIONS).concat(Object.keys(AstIdentMapping));
    const properties = Object.keys(definition);
    const nodeName = properties.find((property) => {
      return property !== 'uuid' && keys.includes(normalize ? this.normalizeId(property)  : property);
    });
    return nodeName as AstFunctionId;
  }

  /** Clones `definition`, puts uuids inside, converts nodeNames to upper-case, decodes attributes/values */
  static denormalize(definition: SuperAstType, shouldClone = true): AstBox {
    if (shouldClone) { definition = clone(definition); }
    if(Object.keys("ast" in definition ? definition.ast : definition).length === 0) return {ast: {}, uuid: definition.uuid, showUnanswered: definition.showUnanswered};
    Ast.forEach("ast" in definition ? definition.ast : definition, (node: AstType) => {
      const fncId = Ast.getNodeId(node);
      const fncIdNormal = this.normalizeId(fncId)
      if (fncId !== undefined && fncId !== fncIdNormal) {
        const ref = node[fncId];
        delete node[fncId];
        node[fncIdNormal as any] = ref;
      }
      if (fncIdNormal !== undefined && this.isArray(node[fncIdNormal])) {
        const array = node[fncIdNormal] as AstType[]
        array.forEach((el) => {
          const nodeName = Ast.getNodeId(el)
          if(nodeName) {
            const nodeNameNormalized = this.normalizeId(nodeName)
            if (nodeName !== nodeNameNormalized) {
              const temp = el[nodeName] as AstType;
              delete el[nodeName];
              el[nodeNameNormalized as any] = temp;
            }
          }
        })
      }
      (node as any).uuid = Ast.generateUUID();

      if (node["Att"] !== undefined) { node["Att"] = AvvParser.decode(node["Att"]); }
      if (node["Iterator"] !== undefined) { node["Iterator"] = AvvParser.decode(node["Iterator"]); }
      if (node["AttArray"] !== undefined) { node["AttArray"] = AvvParser.decode(node["AttArray"]); }
      if (node["String"] !== undefined) { node["String"] = AvvParser.decode(node["String"]); }
    });
    return definition;
  }

  /** Clones `definition`, removes uuids inside, encodes attributes/values */
  static normalize(definition: SuperAstType): SuperAstType {
    definition = clone(definition);
    Ast.forEach("ast" in definition ? definition.ast : definition, (node) => {
      delete (node as any).uuid;

      if (node["Att"] !== undefined) { node["Att"] = AvvParser.encode(node["Att"]); }
      if (node["Iterator"] !== undefined) { node["Iterator"] = AvvParser.encode(node["Iterator"]); }
      if (node["AttArray"] !== undefined) { node["AttArray"] = AvvParser.encode(node["AttArray"]); }
      if (node["String"] !== undefined) { node["String"] = AvvParser.encode(node["String"]); }
    });
    return definition;
  }

  static fromState(state: AstStoreType): {ast: AstType} {
    const result = Object.create(null) as AstBox
    const relation = toRaw(state.byUUID)
    const resolve = (uuid: string) => {
      const element = Object.create(null) as Record<AstFunctionId, unknown>;
      const temp = relation[uuid];
      if(temp == null) return element;

      const nodeId = this.getNodeId(temp);
        if(nodeId == null) return element;

      const value = temp[nodeId];
      if(Array.isArray(value)) {
        element[nodeId] = value.map(arrUUID => resolve(arrUUID))
      } else if(typeof value === "string" || typeof value === "number") {
        element[nodeId] = value;
      } else {
        element[nodeId] = resolve(value[nodeId])
      }
      return element;
    }
    result.ast = resolve(toRaw(state.main));
    return result;
  }

  static toState(definition: SuperAstType): AstStoreType {
    if ("ast" in definition) definition = definition.ast
    if (!("uuid" in definition)) definition = this.denormalize(definition)
    definition = definition as AstType & {uuid: string};
    const attributes: Set<string> = new Set(), values: Set<string> = new Set()
    const relation = Object.create(null)
    const isEmpty = this.getNodeId(definition, false) == null
    if(!isEmpty) {
      Ast.forEach(definition, (value: AstType & {uuid: string}, parent: AstType & {uuid: string}) => {
        const pUUID = parent.uuid;
        const pId = this.getNodeId(parent)
        if(relation[pUUID] === undefined) {
          relation[pUUID] = {[pId]: []}
        }

        const nodeId = this.getNodeId(value);
        const uuid = value.uuid;
        const bIsAttribute = ["Att", "AttArray", "DefaultAtt"].includes(nodeId);
        const bIsValue = ["String", "Number", "Iterator", "Sysdate", "Boolean"].includes(nodeId);
        const bIsEndValue = bIsAttribute || bIsValue;
        const bIsValidEndValue = typeof value[nodeId] === "string" || typeof value[nodeId] === "number"

        if(bIsAttribute && bIsValidEndValue) attributes.add(value[nodeId]);
        if(bIsValue && bIsValidEndValue) values.add(value[nodeId]);

        const parentRelation = relation[pUUID]
        const parentArray = parentRelation[pId]
        if(!parentArray.includes(uuid) && uuid !== pUUID) {
          parentArray.push(uuid)
        }

        if((bIsEndValue && bIsValidEndValue) || value[nodeId].length === 0) {
          relation[uuid] = {[nodeId]: value[nodeId]}
        }
      })
    }
    return {
      byUUID: relation,
      main: (definition as any).uuid,
      values: Array.from(values),
      ast_menu: null,
      attributes: Array.from(attributes),
      operations: []
    }
  }

  public static generateUUID(): string {
    let uuid = '',
      i,
      random;
    for (i = 0; i < 32; i++) {
      random = (Math.random() * 16) | 0;

      if (i === 8 || i === 12 || i === 16 || i === 20) {
        uuid += '-';
      }
      // tslint:disable-next-line:no-bitwise
      uuid += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16);
    }
    return uuid;
  }

  public static convertToHumanReadable(definition: AstType | SuperAstType, addNodeName = false, withHtml = false, custom = false):string {
    const sinaString = ('showUnanswered' in definition && definition.showUnanswered) ? '(trigger if not answered)' : ''
    if ("ast" in definition) definition = definition.ast
    if (Array.isArray(definition) && definition.length === 0) { return '' }
    if (definition instanceof Object && Object.keys(definition).length === 0) { return '' }
    function showLevel(node: AstType | string | number | AstType[], isTopNode = false): string {
      if(typeof node !== 'string' && typeof node !== 'number' && !Array.isArray(node)){
        const widget = Ast.matchWidget(node)
        if(custom && widget && widget.custom) {
          return widget.custom(node)
        }
      }
      if (typeof node === 'string') { return AvvParser.decode(node); }
      if (typeof node === 'number') { return node.toString(); }
      if (typeof node === 'undefined') { return ''; }
      if (Array.isArray(node)) {
        return node.map((item) => showLevel(item)).join(', ');
      }
      if (Ast.isObject(node)) {
        const nodeName = Ast.getNodeId(node);
        if (nodeName === undefined) { return '' }
        const innerNode = node[nodeName];
        const nodeNameString = addNodeName ? `_AVV${nodeName}AVV_` : ''
        const conditionPartOne = showLevel((innerNode as AstType[])[0])
        const conditionPartTwo = showLevel((innerNode as AstType[])[1])
        let symbol = ''

        let getOutputForSymbols = () => {
          if (withHtml) {
            const maxWidthArray = getMaxWidthForTwoStrings(conditionPartOne, conditionPartTwo, 4)
            return `<p class="truncate-children"><span title="${conditionPartOne}" class="align-bottom inline-block" style="max-width: ${maxWidthArray[0]}%">${conditionPartOne}</span> ${symbol} <span title="${conditionPartTwo}" class="align-bottom inline-block" style="max-width: ${maxWidthArray[1]}%">${conditionPartTwo}</span></p>`
          }
          return `${conditionPartOne} ${symbol} ${conditionPartTwo}`
        }
        switch (nodeName) {
          case 'Equals':
            symbol = '='
            return getOutputForSymbols()
          case 'NotEquals':
            symbol = '≠'
            return getOutputForSymbols()
          case 'Greater':
            symbol = '>'
            return getOutputForSymbols()
          case 'GreaterEqual':
            symbol = '>='
            return getOutputForSymbols()
          case 'Less':
            symbol = '<'
            return getOutputForSymbols()
          case 'LessEqual':
            symbol = '<='
            return getOutputForSymbols()
          case 'Att':
            return `${nodeNameString}${showLevel(innerNode as string)}`;
          case 'String':
            return `${nodeNameString}${showLevel(innerNode as string)}`
          case 'And':
            const andResult = (innerNode as AstType[]).map((el) => showLevel(el)).join(' AND ')
            return isTopNode ? andResult : `(${andResult})`
          case 'Or':
            const orResult = (innerNode as AstType[]).map((el) => showLevel(el)).join(' OR ')
            return isTopNode ? orResult : `(${orResult})`
          case 'Number':
            return `${nodeNameString}${showLevel(innerNode as number)}`;
          default:
            return `${nodeName}(${showLevel(innerNode as any)})`;
        }
      }
      return 'undefined';
    }
    return `${showLevel(definition, true)} ${sinaString}`;
  }

  public static astStringToHumanReadable(ast: string | AstType | SuperAstType, addNodeName = false, custom = false): string | null {
    if(!ast) return NO_VIS_COND_HUMAN
    const isParsed = typeof ast !== 'string'
    let parsedAst = isParsed ? ast : Ast.parse(ast)
    if(!parsedAst || typeof parsedAst !== 'object') return null
    if('ast' in parsedAst) parsedAst = parsedAst.ast
    return Ast.convertToHumanReadable(parsedAst, false, false, custom)
  }

  public static traverseIsArray(traversalResult: {object?: any, name?: string} | {array?: any[], index?: number} | undefined): traversalResult is {array: any[], index: number} {
    return traversalResult !== undefined && (traversalResult as any).index !== undefined && (traversalResult as any).array !== undefined
  }

  public static traverseIsObject(traversalResult: {object?: any, name?: string} | {array?: any[], index?: number} | undefined): traversalResult is {object: any, name: string} {
    return traversalResult !== undefined && (traversalResult as any).name !== null && (traversalResult as any).object !== undefined
  }

  public static isArray<T extends AstType | string | number>(definition: T | T[]): definition is T[] {
    return Array.isArray(definition) as any;
  }

  public static isObject(definition: AstType | string | number | AstType[]): definition is AstType {
    return !Array.isArray(definition) && typeof definition === "object"
  }

  public static uniqueArray<Arr>(array: Arr[]): Arr[] {
    return array.filter((value, index, self) => self.indexOf(value) === index);
  }
}

const getMaxWidthForTwoStrings = (stringOne: string, stringTwo: string, offset: number) => {
  const stringOneLength = stringOne.length
  const stringTwoLength = stringTwo.length
  const totalLength = stringOneLength + stringTwoLength

  let stringOneWidth = Math.floor((stringOneLength / totalLength) * 100)
  let stringTwoWidth = Math.floor((stringTwoLength / totalLength) * 100)

  // Make sure both widths are at least 10 %:
  if (stringOneWidth < 10) {
    stringOneWidth = 10
    stringTwoWidth = 100 - stringOneWidth - offset
  } else if (stringTwoWidth < 10) {
    stringTwoWidth = 10
    stringOneWidth = 100 - stringTwoWidth - offset
  } else {
    if (stringOneWidth >= stringTwoWidth) stringOneWidth = stringOneWidth - offset
    else stringTwoWidth = stringTwoWidth - offset
  }
  return [stringOneWidth, stringTwoWidth]
}
